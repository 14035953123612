.card-link
  display: inline-flex
  text-decoration: none !important

.app-card
  display: inline-flex
  flex-direction: column
  align-items: flex-start
  padding: 18px
  width: 320px
  min-height: 208px
  margin-right: 20px
  margin-bottom: 20px
  box-shadow: none !important
  border: 1px solid #eee !important
  position: relative

  &:hover
    background-color: #fafafa !important

  .app-card-icon
    height: 40px
    witdh: 40px
    max-height: 40px
    max-width: 40px

  .app-card-title
    margin-top: 12px
    font-size: 18px
    font-weight: 400
    color: rgba(0, 0, 0, .87)

  .app-card-labels
    position: absolute
    right: 18px

    .app-card-label
      background-color: #F59C00

  .app-card-description
    margin-top: 8px
    font-size: 16px
    color: rgba(0, 0, 0, .6)

  .app-card-spacer
    flex-grow: 1

  .app-card-link
    margin-top: 16px
    display: inline-flex
    align-items: center
    text-decoration: none
    font-size: 14px
    line-height: 20px
    color: #524488
    margin-left: 10px
    font-weight: 600

    svg
      font-size: 14px
      margin-right: 5px
